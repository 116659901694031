<template>
  <div class="company-form">
    <div class="card">
      <div class="card-header card-header-divider">Компания</div>
      <div class="card-body">
        <div v-if="isPreloader" class="table-preloader">
          <preloader />
        </div>
        <form v-else action="#" @submit.prevent="submitOrganization" novalidate>
          <div class="company-form__inputs">
            <div class="company-form__area company-form__area1">
              <custom-input
                v-model="form.value.name"
                name="name"
                label="Наименование компании*"
                req
                :disabled="!getRight('companyEdit')"
              />
              <custom-input
                v-model="form.value.inn"
                maska="############"
                name="inn"
                label="ИНН*"
                req
                :disabled="!getRight('companyEdit')"
              />
              <custom-select-2
                v-if="getRight('companyDistribution')"
                label="Администратор"
                name="owner_id"
                v-model="form.value.owner_id"
                :settings="usersAjax"
              />
              <custom-input
                v-model="form.value.site"
                name="site"
                label="WWW-страница"
                :disabled="!getRight('companyEdit')"
              />
            </div>
            <div
              class="
                company-form__area company-form__area2
                display--flex
                justify-content-center
                align-item-center
              "
            >
              <preview-file-loader
                label="Логотип компании"
                name="image"
                @loadPhoto="
                  (data) => {
                    form.value.image = data;
                    form.value.currentImage = null;
                  }
                "
                @delete="
                  () => {
                    form.value.currentImage = null;
                    form.value.image = null;
                  }
                "
                :image="
                  form.value.currentImage
                    ? form.value.currentImage
                    : form.value.image
                "
                :disabled="!getRight('companyEdit')"
              />
            </div>
            <div class="company-form__area company-form__area3">
              <custom-input
                v-model="form.value.direction_of_activity"
                name="direction_of_activity"
                label="Направление деятельности"
                :disabled="!getRight('companyEdit')"
              />

              <custom-input
                v-model="form.value.phone"
                name="phone"
                type="tel"
                label="Телефон"
                :disabled="!getRight('companyEdit')"
              />
              <custom-input
                v-model="form.value.organization_type"
                name="organization_type"
                label="Вид организации"
                :disabled="!getRight('companyEdit')"
              />
              <custom-input
                v-model="form.value.kpp"
                maska="#########"
                name="kpp"
                label="КПП"
                :disabled="!getRight('companyEdit')"
              />
            </div>
            <div class="company-form__area company-form__area4">
              <custom-input
                v-model="form.value.ogrn"
                maska="#############"
                name="ogrn"
                label="ОГРН"
                :disabled="!getRight('companyEdit')"
              />
              <custom-input
                v-model="form.value.legal_address"
                name="legal_address"
                label="Юр. Адрес"
                :disabled="!getRight('companyEdit')"
              />
              <custom-input
                v-model="form.value.legal_short_name"
                name="legal_short_name"
                label="Краткое название компании (для документов)"
                :disabled="!getRight('companyEdit')"
              />
              <custom-input
                v-model="form.value.legal_full_name"
                name="legal_full_name"
                label="Полное название компании (для документов)"
                :disabled="!getRight('companyEdit')"
              />
            </div>
          </div>
          <div
            v-if="getRight('companyEdit')"
            class="
              company-form__btns
              mt-15
              mb-10
              display--flex
              justify-content-end
            "
          >
            <button type="submit" class="btn btn-accent mr-10">
              Сохранить
            </button>
            <button type="button" @click="router.go(-1)" class="btn btn-w">
              Назад
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "./Fields/CustomInput.vue";
import { useStore } from "vuex";
import useErrors from "../../mixins/useErrors";
import { reactive, ref } from "@vue/reactivity";
import PreviewFileLoader from "./Fields/PreviewFileLoader.vue";
import standartError from "../../mixins/standartError";
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from "@vue/runtime-core";
import useCheckRights from "../../mixins/useCheckRights";
import { useRoute, useRouter } from "vue-router";
import Preloader from "../Technical/Preloader.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import CustomSelect2 from "./Fields/CustomSelect2.vue";

export default {
  components: { CustomInput, PreviewFileLoader, Preloader, CustomSelect2 },
  name: "company-form",
  setup() {
    const store = useStore(),
      { setErrors, clearCustomErrors } = useErrors(),
      { getRight } = useCheckRights(),
      route = useRoute(),
      router = useRouter(),
      isPreloader = ref(false);
    const { usersAjax } = useAjaxSelect();
    const form = reactive({
      value: {
        name: "",
        site: "",
        direction_of_activity: "",
        phone: "",
        organization_type: "",
        inn: "",
        kpp: "",
        ogrn: "",
        legal_address: "",
        legal_short_name: "",
        legal_full_name: "",
        image: null,
        currentImage: null,
        owner_id: null,
      },
    });
    const currentCompany = computed(() => store.state.company.userCompany),
      company = computed(() => store.state.company.company),
      numberUserId = computed(() =>
        form.value.owner_id ? Number(form.value.owner_id) : null
      ),
      isCreateAdminNewCompany = computed(
        () => route.path === "/company/create"
      );

    onBeforeMount(() => {
      if (route.params.id) {
        isPreloader.value = true;
        store.dispatch("company/getCompany", route.params.id).then(() => {
          form.value = {
            ...company.value,
            owner_id: company.value.owner_id
              ? String(company.value.owner_id)
              : null,
          };
          isPreloader.value = false;
        });
        return;
      }
      if (!isCreateAdminNewCompany.value) {
        isPreloader.value = true;
        store.dispatch("company/getUserCompany").then(() => {
          form.value = {
            ...currentCompany.value,
            owner_id: currentCompany.value.owner_id
              ? String(currentCompany.value.owner_id)
              : null,
          };
          isPreloader.value = false;
        });
      }
    });

    onBeforeUnmount(() => {
      store.commit("company/setCompany", null);
      store.commit("company/setUserCompany", null);
    });

    const submitOrganization = () => {
      if (
        (currentCompany.value && Object.keys(currentCompany.value).length) ||
        (company.value && Object.keys(company.value).length)
      ) {
        store
          .dispatch("company/updateCompany", {
            id: route.params.id ? company.value.id : currentCompany.value.id,
            company: { ...form.value, owner_id: numberUserId.value },
          })
          .then(() => store.commit("setCompanyTrap", false))
          .catch(standartError(setErrors, clearCustomErrors));
        return;
      }
      store
        .dispatch("company/createCompany", {
          ...form.value,
          owner_id: numberUserId.value,
        })
        .then(() => {
          store.commit("setCompanyTrap", false);
          if (isCreateAdminNewCompany.value) {
            router.push("/organizations");
          }
        })
        .catch(standartError(setErrors, clearCustomErrors));
    };

    watch(
      () => route.params.id,
      () => {
        if (route.params.id) {
          isPreloader.value = true;
          store.dispatch("company/getCompany", route.params.id).then(() => {
            form.value = { ...company.value };
            isPreloader.value = false;
          });
          return;
        }
        if (route.path !== "/company/create") {
          isPreloader.value = true;
          store.dispatch("company/getUserCompany").then(() => {
            form.value = { ...currentCompany.value };
            isPreloader.value = false;
          });
          return;
        }
      }
    );

    return {
      form,
      submitOrganization,
      currentCompany,
      getRight,
      router,
      isPreloader,
      company,
      usersAjax,
    };
  },
};
</script>

<style lang="scss" scoped>
.company-form {
  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;

    @media (max-width: 1480px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>